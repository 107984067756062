<template>
    <div class="main">
        <div class="pb-5">
            <div class="row pb-md-5">
                <div class="col-12 mt-2">
                    <div class="card">
                        <div class="row p-1 pt-0">
                            <div class="col-12 pb-1">
                                <span class="font-weight-bolder">{{
                                    t("filters")
                                }}</span>
                            </div>
                            <div class="col-lg-2 col-12 col-sm-6 col-md-3">
                                <label>{{ t("phone") }}</label>
                                <input
                                    v-model="searchPhone"
                                    type="text"
                                    class="form-control"
                                    placeholder=""
                                />
                            </div>
                            <div class="col-lg-2 col-12 col-sm-6 col-md-3">
                                <label>{{ t("dateRange") }}</label>
                                <flat-pickr
                                    v-model="range"
                                    class="form-control text-center"
                                    :config="{ mode: 'range' }"
                                />
                            </div>
                            <div class="col-lg-2 col-12 col-sm-6 col-md-3">
                                <label>{{ t("status") }}</label>
                                <v-select
                                    v-model="searchStatus"
                                    :options="statusOptions"
                                    :get-option-label="
                                        (option) => t(option.label)
                                    "
                                ></v-select>
                            </div>
                            <div class="col-lg-2 col-12 col-sm-6 col-md-3">
                                <label>{{ t("minPrice") }}</label>
                                <input
                                    v-model="minPrice"
                                    type="number"
                                    class="form-control"
                                    placeholder=""
                                />
                            </div>
                            <div class="col-lg-2 col-12 col-sm-6 col-md-3">
                                <label>{{ t("maxPrice") }}</label>
                                <input
                                    v-model="maxPrice"
                                    type="number"
                                    class="form-control"
                                    placeholder=""
                                />
                            </div>
                            <div
                                class="
                                    col-lg-2 col-12 col-sm-6 col-md-3
                                    pt-2 pt-lg-2
                                "
                            >
                                <b-button
                                    variant="primary"
                                    @click="filterMembers()"
                                    ><feather-icon icon="SearchIcon" />
                                    {{ t("search") }}</b-button
                                >
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-12">
                    <div class="card">
                        <div>
                            <b-modal
                                size="lg"
                                id="order-items"
                                centered
                                ok-only
                                :ok-title="t('close')"
                                :title="t('orderInfo')"
                            >
                                <div class="mt-1 mb-1">
                                    <div class="pb-1">
                                        <b-list-group>
                                            <b-list-group-item>
                                                <div class="row">
                                                    <div
                                                        class="
                                                            col-5 col-md-3
                                                            text-primary
                                                        "
                                                    >
                                                        {{ t("orderNo") }}
                                                    </div>
                                                    <div class="col">
                                                        {{ order.orderNo }}
                                                    </div>
                                                </div>
                                            </b-list-group-item>
                                            <b-list-group-item>
                                                <div class="row">
                                                    <div
                                                        class="
                                                            col-4 col-md-3
                                                            text-primary
                                                        "
                                                    >
                                                        {{ t("orderId") }}
                                                    </div>
                                                    <div class="col">
                                                        {{ order.orderId }}
                                                    </div>
                                                </div>
                                            </b-list-group-item>
                                            <b-list-group-item>
                                                <div class="row">
                                                    <div
                                                        class="
                                                            col-4 col-md-3
                                                            text-primary
                                                        "
                                                    >
                                                        {{ t("paymentMethod") }}
                                                    </div>
                                                    <div class="col">
                                                        {{
                                                            order.paymentMethod
                                                        }}
                                                    </div>
                                                </div>
                                            </b-list-group-item>
                                            <b-list-group-item>
                                                <div class="row">
                                                    <div
                                                        class="
                                                            col-4 col-md-3
                                                            text-primary
                                                        "
                                                    >
                                                        {{ t("clientName") }}
                                                    </div>
                                                    <div class="col">
                                                        {{ order.userName }}
                                                    </div>
                                                </div>
                                            </b-list-group-item>
                                            <b-list-group-item>
                                                <div class="row">
                                                    <div
                                                        class="
                                                            col-4 col-md-3
                                                            text-primary
                                                        "
                                                    >
                                                        {{ t("address") }}
                                                    </div>
                                                    <div class="col">
                                                        {{ order.userAddress }}
                                                    </div>
                                                </div>
                                            </b-list-group-item>
                                            <b-list-group-item>
                                                <div class="row">
                                                    <div
                                                        class="
                                                            col-4 col-md-3
                                                            text-primary
                                                        "
                                                    >
                                                        {{ t("remark") }}
                                                    </div>
                                                    <div class="col">
                                                        {{ order.remark }}
                                                    </div>
                                                </div>
                                            </b-list-group-item>
                                            <b-list-group-item>
                                                <div class="row">
                                                    <div
                                                        class="
                                                            col-4 col-md-3
                                                            text-primary
                                                        "
                                                    >
                                                        {{ t("paymentTime") }}
                                                    </div>
                                                    <div class="col">
                                                        {{ order.payTime }}
                                                    </div>
                                                </div>
                                            </b-list-group-item>
                                            <b-list-group-item>
                                                <div class="row">
                                                    <div
                                                        class="
                                                            col-4 col-md-3
                                                            text-primary
                                                        "
                                                    >
                                                        {{ t("createdAt") }}
                                                    </div>
                                                    <div class="col">
                                                        {{ order.createdAt }}
                                                    </div>
                                                </div>
                                            </b-list-group-item>
                                        </b-list-group>
                                    </div>
                                    <h4>{{ t("items") }}</h4>
                                    <b-table
                                        :items="order.orderItems"
                                        :fields="orderFields"
                                        responsive
                                        class="rounded border"
                                    >
                                        <template #head()="scope">
                                            <div
                                                class="text-nowrap text-primary"
                                            >
                                                {{ t(scope.column) }}
                                            </div>
                                        </template>
                                        <template #cell()="data">
                                            <span class="text-nowrap">
                                                {{ data.value }}
                                            </span>
                                        </template>
                                        <template #cell(itemPrice)="data">
                                            <span class="text-nowrap">
                                                {{ m(data.value) }}
                                                <small class="text-primary"
                                                    >XAF</small
                                                >
                                            </span>
                                        </template>
                                        <template #cell(itemCoverImage)="data">
                                            <span class="text-nowrap">
                                                <b-img
                                                    :src="data.value"
                                                    style="height: 40px"
                                                />
                                            </span>
                                        </template>
                                    </b-table>
                                    <h3>
                                        {{ t("totalPrice") }}:
                                        {{
                                            m(getTotalPrice(order.orderItems))
                                        }}
                                        <small class="text-primary">XAF</small>
                                    </h3>
                                </div>
                            </b-modal>
                        </div>
                        <div class="p-1">
                            <h2>{{ t("Orders") }}</h2>
                        </div>
                        <b-overlay :show="itemsBusy">
                            <b-table
                                id="users-table"
                                :per-page="perPage"
                                :current-page="1"
                                responsive
                                :items="items"
                                :fields="fields"
                            >
                                <!-- <template #table-busy>
                                    <div class="text-center text-primary my-2">
                                        <b-spinner class="align-middle mr-1"></b-spinner>
                                        <strong>Loading...</strong>
                                    </div>
                                </template> -->
                                <template #head()="scope">
                                    <div class="text-nowrap text-primary">
                                        {{ t(scope.column) }}
                                    </div>
                                </template>
                                <template #head(userPhone)>
                                    <div class="text-nowrap text-primary">
                                        {{ t("phone") }}
                                    </div>
                                </template>
                                <template #head(totalPrice)>
                                    <div class="text-nowrap text-primary">
                                        {{ t("totalPrice") }}
                                    </div>
                                </template>
                                <template #head(orderItems)>
                                    <div class="text-nowrap text-primary">
                                        {{ t("orderItems") }}
                                    </div>
                                </template>

                                <template #cell(orderStatus)="data">
                                    <span
                                        class="text-nowrap"
                                        v-if="data.item.orderId"
                                    >
                                        <span
                                            :class="`alert rounded ${
                                                status[1][data.value]
                                            }`"
                                            >{{
                                                t(status[0][data.value])
                                            }}</span
                                        >
                                    </span>
                                </template>

                                <template #cell()="data">
                                    <span
                                        class="text-nowrap"
                                        v-if="data.item.orderId"
                                    >
                                        {{ data.value }}
                                    </span>
                                </template>

                                <template #cell(orderItems)="data">
                                    <span
                                        class="text-nowrap"
                                        v-if="data.item.orderId"
                                    >
                                        {{ data.value.length }}
                                    </span>
                                </template>

                                <template #cell(createdAt)="data">
                                    <span
                                        class="text-nowrap"
                                        v-if="data.item.orderId"
                                    >
                                        {{ formatDate(data.value) }}
                                    </span>
                                    <span
                                        class="d-block text-nowrap pt-2"
                                        v-else
                                    >
                                    </span>
                                </template>

                                <template #cell(actions)="data">
                                    <div v-if="data.item.orderId">
                                        <span class="text-nowrap">
                                            <b-button
                                                @click="
                                                    viewOrder(data.item.orderId)
                                                "
                                                size="sm"
                                                variant="outline-primary"
                                                class="rounded-pill"
                                            >
                                                <feather-icon icon="FileIcon" />
                                                {{ t("viewItems") }}</b-button
                                            >
                                        </span>
                                    </div>
                                </template>
                            </b-table>
                        </b-overlay>
                        <div class="d-flex p-1">
                            <div class="w-100">
                                {{ t("page") }}
                                <span class="text-primary">
                                    {{ currentPage }}</span
                                >
                                {{ t("of") }}
                                <span class="text-primary">{{
                                    Math.ceil(totalRows / perPage)
                                }}</span>
                            </div>
                            <div class="w-100">
                                <b-pagination
                                    v-model="currentPage"
                                    :total-rows="rows"
                                    :per-page="perPage"
                                    aria-controls="users-table"
                                    align="right"
                                    size="sm"
                                    class="my-0"
                                    @page-click="loadNewPage"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import { useUtils as useI18nUtils } from "@core/libs/i18n";
import commonApi from "@/services/api";
import data from "@/router/routes/ui-elements";
import Utils from "@/utils/index";
import { getUserData, isUserLoggedIn } from "@/auth/utils";
import {
    BListGroup,
    BListGroupItem,
    BImg,
    BModal,
    BDropdownDivider,
    BDropdownItem,
    BPagination,
    BBadge,
    BDropdown,
    BTable,
    BRow,
    BFormFile,
    BCol,
    BCard,
    BCardBody,
    BFormTags,
    BButton,
    BCardText,
    BForm,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupPrepend,
    BFormTextarea,
    BFormCheckbox,
    BPopover,
    VBToggle,
    BFormInvalidFeedback,
    BOverlay,
} from "bootstrap-vue";
import Api from "../api";

export default {
    name: "Main",
    components: {
        BListGroup,
        BListGroupItem,
        BImg,
        BModal,
        BDropdownDivider,
        BDropdownItem,
        BPagination,
        BBadge,
        BDropdown,
        BTable,
        BOverlay,
        vSelect,
        flatPickr,
        BRow,
        BCol,
        BCard,
        BCardBody,
        BButton,
        BCardText,
        BForm,
        BFormGroup,
        BFormInput,
        BInputGroup,
        BInputGroupPrepend,
        BFormTextarea,
        BFormCheckbox,
        BPopover,
        BFormTags,
        BFormFile,
        VBToggle,
        BFormInvalidFeedback,
    },
    data() {
        return {
            userName: "",
            location: "",
            order: [],
            orderFields: [
                { key: "itemName" },
                { key: "itemPrice" },
                { key: "quantity" },
                { key: "itemCoverImage" },
            ],
            minPrice: "",
            maxPrice: "",
            uploading: false,
            usersBusy: false,
            itemsBusy: true,
            user: "",
            perPage: 10,
            totalRows: 0,
            currentPage: 1,
            range: "",
            searchDate: "",
            searchPhone: "",
            searchStatus: "",
            searchParams: {},
            orderId: "",
            status: [
                {
                    1: "unpaid",
                    2: "paid",
                    3: "processing",
                    4: "completed",
                    "-1": "cancelledByUser",
                    "-2": "cancelledBySystem",
                },
                {
                    1: "alert-dark",
                    2: "alert-primary",
                    3: "alert-warning",
                    4: "alert-success",
                    "-1": "alert-danger",
                    "-2": "alert-danger",
                },
            ],
            statusOptions: [
                { label: "unpaid", value: 1 },
                { label: "paid", value: 2 },
                { label: "processing", value: 3 },
                { label: "completed", value: 4 },
                { label: "cancelledByUser", value: -1 },
                { label: "cancelledBySystem", value: -2 },
                { label: "all", value: "" },
            ],
            search: {},
            title: "team",
            subTitle: "merchant",
            subLink: "/apps/merchant",
            updateName: "",
            role: "",
            sexes: [
                { label: "male", value: 1 },
                { label: "female", value: 2 },
                { label: "other", value: 3 },
                { label: "ratherNotSay", value: 0 },
            ],
            fields: [
                { key: "orderId" },
                { key: "orderNo" },
                { key: "orderStatus" },
                { key: "userPhone" },
                { key: "totalPrice" },
                { key: "orderItems" },
                { key: "createdAt" },
                { key: "actions" },
            ],
            items: [],
        };
    },
    computed: {
        rows() {
            return this.totalRows;
        },
    },
    watch: {
        amount: function (current, old) {
            if (parseFloat(current) && current > 100) {
                this.amountCheck = false;
                this.submit = true;
            } else {
                this.amountCheck = true;
                this.submit = false;
            }
        },
        range(newRange, oldRange) {
            newRange = newRange.split(" to ");
            if (newRange.length === 2) {
                this.searchDate = {
                    from: newRange[0],
                    to: newRange[1],
                };
            } else if (newRange[0].length == 10) {
                this.searchDate = {
                    from: newRange[0],
                    to: newRange[0],
                };
            } else {
                this.searchDate = "";
            }
        },
        items(current, old) {
            this.items = current;
        },
    },
    mounted() {
        this.location = `${location.protocol}//${location.host}/`;
        this.getOrders();
        if (this.items.length % 10 != 0) {
            const loop = 10 - (this.items.length % 10);
            for (let i = 0; i < loop; i++) {
                this.items.push({});
            }
            this.totalRows = this.items.length;
        }
    },
    methods: {
        viewOrder(orderId) {
            const orders = this.items.filter((item) => item.orderId == orderId);
            if (orders) {
                this.order = orders[0];
                this.$bvModal.show("order-items");
            }
        },
        getTotalPrice(orderItems = []) {
            return (
                orderItems
                    .map((item) => item.itemPrice * item.quantity)
                    .reduce((a, b) => parseFloat(a) + parseFloat(b), 0) || 0
            );
        },
        formatDate(date) {
            return Utils.formatDate(date);
        },
        getUniqueListBy(arr, key) {
            return [...new Map(arr.map((item) => [item[key], item])).values()];
        },
        async getOrders(search = {}, pageNum = 1) {
            this.itemsBusy = true;
            Api.getOrders({ searchParams: this.searchParams, pageNum: pageNum })
                .then((res) => {
                    if (res.success) {
                        this.items = res.data.list;
                        // this.items = this.items.concat(res.data.list.filter(user=>{ return user.orderId }));
                        // this.items = this.getUniqueListBy(this.items, 'orderId');
                        if (res.data.list.length < 10) {
                            console.log(this.items);
                            let add = 10 - res.data.list.length;
                            for (let i = 0; i < add; i++) {
                                this.items.push({});
                            }
                        }
                        this.totalRows = res.data.total;
                    }
                    this.itemsBusy = false;
                })
                .catch((err) => {
                    this.itemsBusy = false;
                    for (let i = 0; i < 10; i++) {
                        this.items.push({});
                    }
                    console.log("Error: ", err);
                });
        },
        loadNewPage(e, page) {
            e.preventDefault();
            this.getOrders({}, page);
            this.currentPage = page;
        },
        deleteOrder(orderId) {
            this.$bvModal
                .msgBoxConfirm(this.$t("confirmRemoveMember") + "?", {
                    title: this.$t("confirm"),
                    size: "sm",
                    buttonSize: "sm",
                    okVariant: "danger",
                    okTitle: this.$t("yes"),
                    cancelTitle: this.$t("no"),
                    footerClass: "p-2",
                    hideHeaderClose: false,
                    centered: true,
                    noCloseOnEsc: true,
                    noCloseOnBackdrop: true,
                })
                .then((value) => {
                    if (value) {
                        this.itemsBusy = true;
                        Api.deleteOrder(orderId)
                            .then((res) => {
                                if (res.success) {
                                    this.items = this.items.filter(item => item.orderId != orderId);
                                    this.itemsBusy = false;
                                }
                            })
                            .catch((err) => {
                                console.log(err);
                            });
                    }
                })
                .catch((err) => {
                    // An error occurred
                });
        },
        m(money) {
            return Utils.formatCurrency(money);
        },
        filterMembers() {
            if (
                !this.searchDate &&
                !this.searchPhone &&
                !this.searchStatus &&
                !this.minPrice &&
                !this.maxPrice
            ) {
                this.searchParams = {};
            } else {
                this.searchParams = {};
                if (this.searchPhone) {
                    this.searchParams.userPhone = this.searchPhone;
                }
                if (this.minPrice) {
                    this.searchParams.minPrice = parseFloat(this.minPrice);
                }
                if (this.maxPrice) {
                    this.searchParams.maxPrice = parseFloat(this.maxPrice);
                }
                if (this.searchStatus && this.searchStatus.value) {
                    this.searchParams.orderStatus = this.searchStatus.value;
                }
                if (this.searchDate) {
                    this.searchParams.startDate =
                        this.searchDate.from + " 00:00:00";
                    if (!this.searchDate.to) {
                        this.this.searchDate.to = this.searchDate.from;
                    }
                    this.searchParams.endDate =
                        this.searchDate.to + " 23:59:59";
                }
            }
            this.getOrders({}, 1);
            this.currentPage = 1;
        },
        showToast(message, variant = "danger", title = null) {
            this.$bvToast.toast(`${message}`, {
                title: title || "ERROR",
                autoHideDelay: 5000,
                appendToast: true,
                solid: true,
                variant: variant,
            });
        },
    },
    setup(props) {
        const { t } = useI18nUtils();

        return {
            // i18n
            t,
        };
    },
};
</script>
<style lang="scss" scope="">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
.hover-reduce:hover {
    opacity: 0.7;
}
.pt {
    padding-top: 7.5px !important;
}
.height-fill {
    height: 80%;
    min-height: 80%;
}
.color-light {
    color: #fff !important;
}
.txn-logos {
    margin-bottom: 3px;
    width: 40px;
    border-radius: 2px;
}
</style>